<template lang="pug">
.swiper-carousel(:class="swiperCarouselClasses")
  template(v-if="shouldDisplaySkeleton")
    .swiper-carousel-skeleton.w-100.h-100.overflow-hidden(
      v-if="getLoadingSkeletonAnimation === 'skeleton-img'"
    )
      b-skeleton-img(
        :height="loadingSkeletonHeight"
        :width="loadingSkeletonWidth"
        :aspect="loadingSkeletonAspect !== false ? loadingSkeletonAspect : undefined"
        :no-aspect="loadingSkeletonAspect === false"
        :class="loadingSkeletonClass"
      )
    .row.mx-0.h-100.w-100(
      v-else-if="getLoadingSkeletonAnimation === 'skeleton-slides'"
    )
      .swiper-carousel-skeleton.col.d-flex(v-for="i in getVisibleSlidesCount()", :key="`skeleton-${i}`")
        b-skeleton.mx-2(
          :height="loadingSkeletonHeight"
          :width="loadingSkeletonWidth"
          :class="loadingSkeletonClass"
        )

  slot(
    v-if="navigationPosition === 'top'"
    name="navigation"
    v-bind="overlaySlotProps"
  )

  .swiper.h-100.w-100(
    ref="swiperContainer"
    :class="{ 'swiper-3d': effect === 'coverflow', 'invisible pe-none position-absolute top-0': shouldDisplaySkeleton }"
    :style="swiperContainerStyle"
  )
    .swiper-wrapper
      .swiper-slide(
        v-for="(slide, index) in clonedSlicedSlides"
        :key="slide.key"
        :class="slideClasses"
      )
        component(
          :is="slide"
          v-if="!onlyRenderVisibleSlides || !!slidesVisible[index]"
        )

  .swiper-carousel-overlay(:class="overlayClasses")
    slot(
      v-if="navigationPosition === 'overlay'"
      name="overlay"
      v-bind="overlaySlotProps"
    )
      template(v-if="shouldDisplayNavigation")
        template(v-if="currentBreakpoint.navigation")
          .cursor-pointer(
            :class="{ 'opacity-50 pe-none': isArrowDisabled('prev') }"
            @click="handleSlidePrevClick"
          )
            slot(name="prevArrowContainer")
              .position-absolute.top-0.left-0.h-100.d-flex.align-items-center.z-5
                .swiper-carousel-arrow.swiper-carousel-arrow-prev(:class="arrowClasses")
                  slot(name="prevArrow")
                    fa(
                      v-if="$isCharter"
                      icon="chevron-left"
                    )
                    FaSharpRegularChevronLeft(
                      v-else
                      fill="currentColor"
                    )

          .cursor-pointer(
            :class="{ 'opacity-50 pe-none': isArrowDisabled('next') }"
            @click="handleSlideNextClick"
          )
            slot(name="nextArrowContainer")
              .position-absolute.top-0.right-0.h-100.d-flex.align-items-center.z-5
                .swiper-carousel-arrow.swiper-carousel-arrow-next(:class="arrowClasses")
                  slot(name="nextArrow")
                    fa(
                      v-if="$isCharter"
                      icon="chevron-right"
                    )
                    FaSharpRegularChevronRight(
                      v-else
                      fill="currentColor"
                    )

        template(v-if="currentBreakpoint.pagination")
          slot(name="paginationContainer")
            .position-absolute.swiper-pagination.w-100.h-auto.d-inline-flex.justify-content-center.z-5
              slot(name="pagination")
                template(v-if="!dynamicBullets")
                  .swiper-pagination-bullet.mx-1.cursor-pointer(
                    v-for="(_, slideIndex) in slideCount"
                    :key="slideIndex"
                    :class="[{ 'swiper-pagination-bullet-active': slideIndex === activeSlideIndex }, dotsClass]"
                    @click="showSlideIndex(slideIndex)"
                  )
                template(v-else)
                  .swiper-pagination-dynamic-bullets.d-inline-flex.align-items-center(:style="{ height: `${activeDynamicDotSizePx}px` }")
                    .swiper-pagination-dynamic-bullet.d-flex.justify-content-center.align-items-center(
                      v-for="(slide, slideIndex) in slideCount"
                      :key="slideIndex"
                      :class="{ 'd-none': !isDynamicDotVisible(slideIndex) }"
                      :style="getDynamicDotSize(slideIndex)"
                    )
                      .swiper-pagination-bullet.h-50.w-50(
                        :class="dotsClass"
                      )

  slot(
    v-if="navigationPosition === 'bottom'"
    name="navigation"
    v-bind="overlaySlotProps"
  )
</template>

<script>
import FaSharpRegularChevronRight from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-right.svg'
import FaSharpRegularChevronLeft from '@fortawesome/fontawesome-pro/svgs/sharp-regular/chevron-left.svg'
import throttle from 'lodash.throttle'
import Swiper from 'swiper'
import { Controller, Autoplay, EffectCoverflow } from 'swiper/modules'

import swiperProps, { SWIPER_OPTIONS_PROPS_KEYS } from '@packages/swiper/constants/props.js'

/**
 * Implementation of Swiper
 * Swiper options @see https://swiperjs.com/types/interfaces/types_swiper_options.SwiperOptions
 * Swiper events @see https://swiperjs.com/swiper-api#events
 */
export default {
  setup () {
    const clonedSlicedSlides = shallowRef([])
    const swiper = shallowRef(null)
    const slideUpdateFunction = ref(() => {})

    const slots = useSlots()

    onMounted(() => nextTick(() => watch(
      () => slots.default(),
      () => slideUpdateFunction.value()
    )))

    const { public: { swiper: config } } = useRuntimeConfig()

    return {
      clonedSlicedSlides,
      swiper,
      slideUpdateFunction,
      config
    }
  },

  components: {
    FaSharpRegularChevronRight,
    FaSharpRegularChevronLeft
  },

  props: swiperProps,

  emits: [
    'beforeSlideChange',
    'activeIndexChange',
    'slideChange',
    'onSlideNextClick',
    'onSlidePrevClick'
  ],

  data () {
    return {
      initialized: false,
      activeSlideIndex: this.initialSlide,
      prevActiveSlideIndex: this.initialSlide,
      slidesVisible: {},
      isSwiperLoopFixActive: false,

      /**
       * currentBreakpoint object can only keep relevant information on client side (naturally)
       * If something won't differ between breakpoints (e.g onlyRenderVisibleSlides or maxSlides) or if the prop/value do major changes
       * - consider using that prop directly and don't use currentBreakpoint for the purpose of SSR
       */
      currentBreakpoint: {},
    }
  },

  computed: {
    slideCount () {
      return slotLength(this.$slots.default?.() || [])
    },

    overlaySlotProps () {
      const {
        slideNext,
        slidePrev,
        showSlideIndex,
        activeSlideIndex
      } = this

      return {
        slideNext,
        slidePrev,
        showSlideIndex,
        activeSlideIndex
      }
    },

    getLoadingSkeletonAnimation () {
      if (!process.browser && this.currentBreakpoint.loadingSkeletonAnimationSSR) {
        return this.loadingSkeletonAnimationSSR
      }

      // Can't calculate current slidesPerView
      if (this.breakpoints && !process.browser && this.currentBreakpoint.loadingSkeletonAnimation === 'skeleton-slides') {
        return 'skeleton-img'
      }

      return this.loadingSkeletonAnimation
    },

    arrowClasses () {
      const classes = ['position-absolute d-flex justify-content-center align-items-center']

      if (this.arrowsColor) {
        classes.push(`text-${this.currentBreakpoint.arrowsColor}`)
      }

      return classes.join(' ')
    },

    slideClasses () {
      const classes = [this.currentBreakpoint.slideClass]

      if (this.currentBreakpoint.slidesPerView === 'auto') {
        classes.push('w-auto')
      }

      if (this.currentBreakpoint.horizontalCenteredSlides) {
        classes.push('my-auto')
      }

      return classes.join(' ')
    },

    isReady () {
      return (
        process.browser &&
        this.clonedSlicedSlides.length !== 0 &&
        this.$refs.swiperContainer
      )
    },

    shouldDisplayNavigation () {
      return (
        this.slideCount > this.getVisibleSlidesCount() ||
        (this.currentBreakpoint.effect === 'coverflow' && this.slideCount > 1) ||
        this.currentBreakpoint.forceDisplayNavigation ||
        this.currentBreakpoint.forceEnablePrev ||
        this.currentBreakpoint.forceEnableNext
      )
    },

    shouldDisplaySkeleton () {
      return (
        !this.initialized ||
        (this.onlyRenderVisibleSlides && !Object.keys(this.slidesVisible).length)
      )
    },

    overlayClasses () {
      const classes = [
        `swiper-carousel-dots-type-${this.currentBreakpoint.dotsType}`,
        `swiper-carousel-dots-align-${this.currentBreakpoint.dotsAlign}`,
        `swiper-carousel-arrows-type-${this.currentBreakpoint.arrowsType}`,
        `swiper-carousel-arrows-size-${this.currentBreakpoint.arrowsSize}`,
        `swiper-carousel-arrows-align-${this.currentBreakpoint.arrowsAlign}`
      ]

      if (this.currentBreakpoint.constantDisplayNavigation || this.currentBreakpoint.forceDisplayNavigation) {
        classes.push('swiper-carousel-arrows-constant')
      }

      if (this.shouldDisplaySkeleton) {
        classes.push('invisible')
      }

      return classes.join(' ')
    },

    swiperCarouselClasses () {
      return {
        'pe-none': this.disabled,
      }
    },

    /**
     * Modules used from swiper/modules/*
     * @see https://swiperjs.com/swiper-api#using-js-modules
     */
    swiperModules () {
      const modules = []

      if (this.usesThumbs) {
        modules.push(Controller)
      }

      if (this.autoplay) {
        modules.push(Autoplay)
      }

      if (this.effect) {
        modules.push(EffectCoverflow)
      }

      return modules
    },

    swiperOptions () {
      const swiperOptionsProps = getObjectOnlyProperties(this.$props, SWIPER_OPTIONS_PROPS_KEYS)

      return {
        ...swiperOptionsProps,

        modules: this.swiperModules,

        navigation: false,
        pagination: false,
        freeMode: this.allowTouchMove ? undefined : {
          enabled: true,
          sticky: true
        },

        centeredSlides: this.verticalCenteredSlides,
        watchSlidesProgress: this.onlyRenderVisibleSlides,
        enabled: this.shouldDisplayNavigation,

        /**
         * Bind event listeners here
         */
        on: {
          afterInit: this.afterSwiperInit,
          slideChange: this.handleSlideChange,
          beforeSlideChangeStart: this.handleBeforeSlideChangeStart,
          realIndexChange: this.handleActiveIndexChange,
          touchMove: this.handleTouchMove,
          transitionEnd: this.handleTransitionEnd,
        }
      }
    },
  },

  watch: {
    $props () {
      if (this.swiper) {
        this.swiper.params = this.swiperOptions
      }
      this.updateCurrentBreakpoint(true)
    },

    thumbs () {
      this.connectThumbs()
    },

    breakpoints () {
      this.updateCurrentBreakpoint(true)
    },

    pagination () {
      this.updateCurrentBreakpoint(true)
    },

    allowTouchMove () {
      this.updateCurrentBreakpoint()
      this.$nextTick(() => this.swiper.update())
    },

    shouldDisplayNavigation () {
      if (!this.swiper) {
        return
      }

      this.swiper.enabled = this.shouldDisplayNavigation
    },

    isReady (value) {
      if (value) {
        this.initializeSwiper()
      }
    }
  },

  created () {
    this.cloneSlides()
    this.slideUpdateFunction = this.cloneSlides

    if (process.browser) {
      this.getVisibleSlidesSwipeThrottle = throttle(() => this.getVisibleSlidesIndices(true), 350)
      this.connectThumbsAbortController = new AbortController()
      this.visibleSlidesInitAbortController = new AbortController()
    }
  },

  mounted () {
    this.updateCurrentBreakpoint()
    window.addEventListener('resize', this.updateCurrentBreakpoint)
  },

  updated () {
    if (this.swiper && !this.preventSwiperUpdate) {
      this.swiper.update()
    }
  },

  beforeUpdate () {
    this.cloneSlides()

    if (this.initialized && this.swiper && this.swiper?.destroyed) {
      this.resetSwiper()
    }
  },

  beforeUnmount () {
    this.connectThumbsAbortController?.abort()
    this.visibleSlidesInitAbortController?.abort()
    window.removeEventListener('resize', this.updateCurrentBreakpoint)
  },

  methods: {
    initializeSwiper () {
      if (!this.initialized) {
        // eslint-disable-next-line no-new
        new Swiper(this.$refs.swiperContainer, this.swiperOptions)
        this.initialized = true
      }
    },

    async afterSwiperInit (swiper) {
      this.swiper = swiper

      this.connectThumbs()

      if (this.onlyRenderVisibleSlides) {
        try {
          await promiseUntilCondition(() => (!this.clonedSlicedSlides.length || this.swiper?.visibleSlides?.length), {
            abortSignal: this.visibleSlidesInitAbortController?.signal,
            interval: 50,
            retries: 1200
          })
        } catch {
          //
        }

        this.getVisibleSlidesIndices()
      }
    },

    async resetSwiper () {
      clearTimeout(this.reInitTimeout)

      this.slideReset()
      await this.swiper.destroy(true, true)

      this.initialized = false
      this.reInitTimeout = window.setTimeout(this.initializeSwiper, 500)
    },

    cloneSlides () {
      /**
       * Fix bug with Swiper
       * @see https://github.com/nolimits4web/swiper/issues/3535
       */
       const maxVisibleSlides = Math.max(
        this.slidesPerView,
        ...Object.values(this.breakpoints || {}).map(({ slidesPerView }) => slidesPerView ?? 0)
      )
      if (
        this.loop &&
        this.slideCount &&
        (
          this.slideCount <= maxVisibleSlides ||
          (this.currentBreakpoint.effect === 'coverflow' && this.slideCount <= maxVisibleSlides * 2)
        )
      ) {
        if (!this.isSwiperLoopFixActive) {
          this.isSwiperLoopFixActive = true
        }

        this.clonedSlicedSlides = new Array(Math.ceil(maxVisibleSlides / this.slideCount))
          .fill(unwrapSlot(this.$slots.default()))
          .reduce(
            (acc, curr, currentIndex) => [
              ...acc,
              ...curr.map(slide => ({ ...slide, key: `${slide.key}-${currentIndex}` }))
            ],
            unwrapSlot(this.$slots.default())
          )
        return
      }

      if (this.isSwiperLoopFixActive) {
        this.isSwiperLoopFixActive = false
      }

      this.clonedSlicedSlides = unwrapSlot(this.$slots.default?.() || []).slice(0, this.slideCount)
    },

    // 1st event fired
    handleBeforeSlideChangeStart (event) {
      const { realIndex } = event
      this.$emit('beforeSlideChange', realIndex, this.activeSlideIndex)
    },

    // 2nd event fired
    handleActiveIndexChange (event) {
      let { realIndex } = event

      if (this.isSwiperLoopFixActive) {
        realIndex = realIndex - (Math.floor(realIndex / this.slideCount) * this.slideCount)
      }

      this.$emit('activeIndexChange', realIndex, this.activeSlideIndex)

      this.prevActiveSlideIndex = this.activeSlideIndex
      this.activeSlideIndex = parseInt(realIndex)
      this.edgeVisibleIndex = this.activeSlideIndex

      this.getVisibleSlidesIndices(true)
    },

    // 3rd event fired
    handleSlideChange (event) {
      const { realIndex } = event
      this.$emit('slideChange', realIndex, this.activeSlideIndex)
    },

    async connectThumbs () {
      if (this.thumbs) {
        await promiseUntilCondition(() => this.thumbs?.swiper?.controller && this.swiper?.controller, {
          abortSignal: this.connectThumbsAbortController?.signal
        })
        this.thumbs.swiper.controller.control = this.swiper
        this.swiper.controller.control = this.thumbs.swiper
      }
    },

    slideNext () {
      this.swiper?.slideNext()
    },

    slidePrev () {
      this.swiper?.slidePrev()
    },

    showSlideIndex (slideIndex, speed = this.speed, runCallbacks = true) {
      this.swiper?.slideTo(slideIndex, speed, runCallbacks)
    },

    handleSlideNextClick () {
      this.$emit(
        'onSlideNextClick',
        this.activeSlideIndex,
        this.getNextSlideIndex()
      )

      if (!this.navigationPreventDefault) {
        this.slideNext()
      }
    },

    handleSlidePrevClick () {
      this.$emit('onSlidePrevClick',
        this.activeSlideIndex,
        this.getPrevSlideIndex()
      )

      if (!this.navigationPreventDefault) {
        this.slidePrev()
      }
    },

    handleTouchMove () {
      if (!this.allowTouchMove) {
        this.swiper.setTranslate(this.swiper.translate)
        this.swiper.updateProgress()
        this.swiper.updateActiveIndex()
        this.swiper.allowSlideNext = false
        this.swiper.allowSlidePrev = false
        return
      }

      this.getVisibleSlidesSwipeThrottle()
    },

    handleTransitionEnd () {
      this.getVisibleSlidesIndices()
    },

    slideReset (speed = 0, runCallbacks = true) {
      this.swiper?.slideReset(speed, runCallbacks)
    },

    updateCurrentBreakpoint (forceUpdate) {
      if (!process.browser) {
        return
      }

      let currentBreakpoint = Object.entries(this.breakpoints || {})
        .map(([breakpoint, options]) => [parseInt(breakpoint), options])
        .sort(([a], [b]) => b - a)
        .find(([breakpoint]) => window.innerWidth >= breakpoint)

      if (!currentBreakpoint) {
        currentBreakpoint = [0, this.$props]
      }

      const [screenWidth, options] = currentBreakpoint

      if (forceUpdate === true || screenWidth !== this.currentBreakpoint.screenWidth) {
        const newCurrentBreakpoint = {
          ...this.$props,
          ...options,
          screenWidth,
        }

        if (this.swiper) {
          /**
           * Fix bug with Swiper not updating visibleSlidesIndices when changing slidesPerView
           */
          if (
            this.onlyRenderVisibleSlides &&
            newCurrentBreakpoint.slidesPerView !== this.currentBreakpoint.slidesPerView
          ) {
            this.resetSwiper()

          /**
           * If not reset, Swiper still needs to update when currentBreakpoint changes
           * If preventSwiperUpdate was false, this would be triggered from updated()
           */
          } else if (this.preventSwiperUpdate) {
            this.$nextTick(() => this.swiper.update())
          }
        }

        this.currentBreakpoint = newCurrentBreakpoint
      }
    },

    getVisibleSlidesCount () {
      if (this.slidesPerView && !this.breakpoints) {
        return this.slidesPerView
      }

      const options = this.currentBreakpoint
      return options?.slidesPerView || this.slidesPerView
    },

    getVisibleSlidesIndices (append) {
      if (!this.onlyRenderVisibleSlides || !this.swiper) {
        return
      }

      let visibleSlideIndices = []
      if (!this.loop) {
        visibleSlideIndices = (this.swiper?.visibleSlidesIndexes || [])
      } else {
        const virtualSlideToIndex = (this.swiper?.visibleSlides || [])
          .map(virtualSlide => virtualSlide?.dataset?.swiperSlideIndex)
          .filter(slideIndex => slideIndex !== undefined)
        visibleSlideIndices = virtualSlideToIndex
      }

      // Weird Swiper bug
      if (!visibleSlideIndices.length && this.clonedSlicedSlides.length && this.swiper.slides?.length) {
        visibleSlideIndices = this.swiper.slides
          .reduce((slideIndices, slideElement, slideIndex) => [
            ...slideIndices,
            ...(['swiper-slide-visible', 'swiper-slide-active'].some((visibleSlideClassName) => slideElement.className?.includes(visibleSlideClassName))
              ? [slideIndex]
              : []
            )
          ], [])
      }

      const slidesVisible = visibleSlideIndices.reduce(
        (obj, slideIndex) => ({ ...obj, [slideIndex]: true }),
        append ? this.slidesVisible : {}
      )

      if (Object.keys(slidesVisible).length) {
        this.slidesVisible = slidesVisible
      }
    },

    getPrevSlideIndex () {
      if (this.activeSlideIndex === 0) {
        if (this.currentBreakpoint.loop || this.currentBreakpoint.rewind) {
          return this.slideCount - 1
        } else {
          return 0
        }
      }

      return this.activeSlideIndex - 1
    },

    getNextSlideIndex () {
      if (this.activeSlideIndex === this.slideCount - 1) {
        if (this.currentBreakpoint.loop || this.currentBreakpoint.rewind) {
          return 0
        } else {
          return this.activeSlideIndex
        }
      }

      return this.activeSlideIndex + 1
    },

    isArrowDisabled (direction) {
      if (!this.initialized || this.disabled) {
        return true
      }

      if (direction === 'prev' && this.currentBreakpoint.forceEnablePrev) {
        return false
      }
      if (direction === 'next' && this.currentBreakpoint.forceEnableNext) {
        return false
      }

      const reachedEnd = direction === 'prev'
        ? this.activeSlideIndex <= 0
        : this.activeSlideIndex + 1 >= this.slideCount

      return (
        !this.currentBreakpoint.loop &&
        !this.currentBreakpoint.rewind &&
        reachedEnd
      )
    },

    isDynamicDotVisible (slideIndex, offset = 2) {
      if (slideIndex === this.activeSlideIndex) {
        return true
      }

      const halfBullets = Math.ceil((this.dynamicMainBullets + offset) / 2)

      if (this.verticalCenteredSlides) {
        if (slideIndex > this.activeSlideIndex - halfBullets && slideIndex < this.activeSlideIndex + halfBullets) {
          return true
        }
      } else if (slideIndex > this.activeSlideIndex && slideIndex < this.activeSlideIndex + (this.getVisibleSlidesCount() - 1)) {
        return true
      }

      return false
    },

    getDynamicDotSize (slideIndex) {
      let multiplier = 0

      if (slideIndex === this.activeSlideIndex) {
        multiplier = 1
      } else if (this.isDynamicDotVisible(slideIndex, 0)) {
        multiplier = 0.75 - (0.1 * Math.abs((slideIndex + 1) - slideIndex))
      }

      const size = `${multiplier * this.activeDynamicDotSizePx}px`
      return {
        height: size,
        width: size
      }
    },
  },
}
</script>

